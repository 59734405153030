import Icon from "app/components/sprites.svg";
import PropTypes from "prop-types";

const Sprite = ({ name, svgRef, dataAnimation, ...props }) => {
  return (
    <svg ref={svgRef} data-animation={dataAnimation} {...props}>
      <use href={`${Icon}#${name}`} />
    </svg>
  );
};

Sprite.propTypes = {
  name: PropTypes.string,
  svgRef: PropTypes.object,
  dataAnimation: PropTypes.string,
};

export default Sprite;
