import { useTranslation } from "react-i18next";

export function useI18nLanguage() {
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const locale = i18n.language;
  const nextLocale = locale === "ar" ? "en" : "ar";

  return {
    nextLocale,
    locale,
    dir,
  };
}
